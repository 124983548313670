import React from 'react'

class Countdown extends React.Component {
    componentDidMount() {
      var countDownDate = new Date('January 11, 2020 08:00:00 GMT').getTime()
      // Update the count down every 1 second
      var x = setInterval(function() {
        // Get todays date and time
        var now = new Date().getTime()
  
        // Find the distance between now an the count down date
        var distance = countDownDate - now
  
        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24))
        var hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        )
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        var seconds = Math.floor((distance % (1000 * 60)) / 1000)
  
        // Output the result in an element with id="demo"
        document.getElementById('countdown-timer').innerHTML =
          days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's '
  
        // If the count down is over, write some text
        if (distance < 0) {
          clearInterval(x)
          document.getElementById('countdown-timer').innerHTML = 'Please refresh the page.'
        }
      }, 1000)
      // document.documentElement.style.overflow = 'hidden'
      // document.body.scroll = 'no'
    }
    render() {
      return (
        <div className="countdown">
              <h2 id="countdown-timer" />
        </div>
      )
    }
  }
  
  export default Countdown