/* eslint-disable */
import React from "react";
import SEO from "../components/seo";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import iphones from '../images/iphones.png'
import logo from "../images/logo.svg";
import Countdown from "../components/countdown";

const ComingSoonPage = () => (
  <>
    <SEO title="Coming Soon" />
    <Container fluid className="coming-soon-page">
      <Row>
        <Col className="text-center">
          <p>
            <img src={logo} className="logo" alt="" />
          </p>
          <h1>Coming Soon</h1>
        </Col>
      </Row>
    </Container>
  </>
);

export default ComingSoonPage;
